<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/tabs/profile/responses"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{
            response && response.survey
              ? response.survey.name
              : $t('messages.loading')
          }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="response">
      <ion-card color="secondary">
        <ion-card-content>
          <div>
            {{
              $t('labels.submittedOn', { date: formatDate(response.created) })
            }}
          </div>
          <div v-if="response.modified">
            {{
              $t('labels.modifiedOn', { date: formatDate(response.modified) })
            }}
          </div>
        </ion-card-content>
      </ion-card>
      <ODNSurvey
        v-if="response.survey"
        :survey="response.survey"
        :response="response"
        :loading="loading"
        @saved="onSaved"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardContent,
  toastController,
} from '@ionic/vue';

import ODNSurvey from '@c/odn-survey';

import APIService from '@s/api.service';

export default {
  name: 'StoreSurvey',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    ODNSurvey,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('responses', {
      response: 'selectedResponse',
    }),
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    ...mapActions('responses', ['selectResponse']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    parseResponseValues(surveyFields) {
      for (const surveyField of surveyFields) {
        if (surveyField.field.isUpload) {
          surveyField.response = surveyField.responseValues[0]
            ? { preview: surveyField.responseValues[0].value, file: null }
            : null;
        } else {
          surveyField.response = surveyField.responseValues[0]?.value || null;
        }
      }
    },
    async fetchData() {
      try {
        const response = (
          await APIService.get(`/responses/${this.$route.params.responseId}`)
        ).data;
        this.parseResponseValues(response.survey.surveyFields);
        this.selectResponse(response);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.surveyResponse.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onSaved() {
      const toastSuccess = await toastController.create({
        message: this.$t('messages.surveyResponse.put.success'),
        color: 'success',
        duration: 2000,
      });
      toastSuccess.present();

      this.$router.go(-1);
    },
  },
};
</script>
